<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Categories
        </v-subheader>
        <v-select
          v-model="metaData.categories"
          :items="categories"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Main Keyword
        </v-subheader>
        <v-text-field
          v-model="metaData.objectMainKeyword"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Meta Keywords
        </v-subheader>
        <v-text-field
          v-model="metaData.objectMetaKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Optional Keywords
        </v-subheader>
        <v-text-field
          v-model="metaData.objectOptionalKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Specific Keywords
        </v-subheader>
        <v-text-field
          v-model="metaData.objectSpecificKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Special Characters Words
        </v-subheader>
        <v-text-field
          v-model="metaData.objectSpecialCharactersWords"
          single-line
          readonly
          outlined
          dense
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import axios from '@/plugins/api'

  export default {
    mixins: [validationMixin],
    props: {
      object: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        metaData: {
          objectMainKeyword: '',
          objectMetaKeywords: '',
          objectOptionalKeywords: '',
          objectSpecificKeywords: '',
          objectSpecialCharactersWords: '',
          categories: [],
        },
        categories: [],
      }
    },
    watch: {
      metaData: {
        handler () {
          this.$emit('updateObject', {
            ...this.object,
            keywords: {
              objectMainKeyword: this.metaData.objectMainKeyword,
              objectMetaKeywords: this.metaData.objectMetaKeywords,
              objectOptionalKeyword: this.metaData.objectOptionalKeywords,
              objectSpecificKeywords: this.metaData.objectSpecificKeywords,
              objectSpecialCharactersWords: this.metaData.objectSpecialCharactersWords,
              categories: this.metaData.categories,
            },
          },
          )
        },
        deep: true,
      },
    },
    created () {
      if (this.object) {
        this.metaData.objectMainKeyword = this.object.keyword.name || ''
        this.metaData.objectMetaKeywords = this.object.keyword.metaKeywords || ''
        this.metaData.objectOptionalKeywords = this.object.keyword.optionalKey || ''
        this.metaData.objectSpecificKeywords = this.object.objectSpecificKeywords || ''
        this.metaData.objectSpecialCharactersWords = this.object.objectSpecialCharactersWords || ''
        this.metaData.categories = this.object.categories.map((category) => category.toString()) || []
      }
    },
    mounted () {
      const _this = this
      axios.get('/admin/categories/all').then(function (response) {
        const categoriesObject = response.data
        _this.categories = Object.keys(categoriesObject).map(function (key) {
          return {
            text: categoriesObject[key],
            value: key,
          }
        })
      })
    },
  }
</script>
